import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@solid-reach/accordion";
import { ShowExhibitionViewProps } from "../model";
import { EXHIBITION } from "../const";
import { Match, Switch, createEffect, createSignal } from "solid-js";
import { InfoCard } from "./info-card";
import { Atom, Cmp, Mod, log, state } from ":mods";
import { ExhibitionDescription } from ".";
import { getShowExhibition } from "../apis/show-exhibition";

export function ShowExhibition({ $, title, description, ...props }: ShowExhibitionViewProps) {
  const [exhibitionData, setEhibitionData] = createSignal(null);
  const [loading, setLoading] = createSignal(true);
  const stage_id = $.actions.getStageSelected();

  createEffect(() => {
    showExhibition(stage_id);
  });

  async function showExhibition(id) {
    await getShowExhibition(id).then((message) => {
      console.log(message, " :message");
      setEhibitionData(message.data);

      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "Exhibition Retrieved successfully",
      });
      setLoading(false);
    });
  }
  //  const $details = state.createAsync([getShowExhibition, id]);

  //  console.log("$details", $details.value.data)
  $.actions.setLayout({
    title: "My Exhibition",
    sideInfo: [
      {
        title: "Enter the Challenge!",
        element: () => <ExhibitionDescription stage_id={stage_id} description={exhibitionData()?.description} />,
      },
    ],
  });

  const goToTasks = (module_name: string, submodule_name: string, id: string | number) => {
    // console.log("goToTasks", id);
    const course_data = $.actions.getCourseData();
    if (!course_data) {
      return;
    }
    const module = course_data.modules.find((x) => x.name === module_name);
    if (!module) {
      log.all.warn(
        "couldn't find module ",
        module_name,
        " in course data, inconsistent data formation between my exhibition and stage list details"
      );
      return;
    }
    const submodule = module.submodule.find((x) => x.name === submodule_name);
    if (!submodule) {
      log.all.warn(
        "couldn't find submodule ",
        submodule_name,
        " in course data, inconsistent data formation between my exhibition and stage list details"
      );
      return;
    }
    const material_idx = submodule.materials.findIndex((x) => x.id === id);
    const route = $.actions.getSubModuleRoute(submodule.id, stage_id);
    //? TODO: set selected index for submodule layer
    // $.actions.setSearchParams({ selected: material_idx });
    $.actions.navigate(route);
  };

  function getCount(num: number) {
    return num < 10 ? "0" + num : num;
  }

  return (
    <Switch>
      <Match when={loading() === true}>
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Exhibition"
            msg="Loading exhibition info..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      </Match>
      {/* <Match when={$db_exhibition.state.failed}>an error occured while fetching {$db_exhibition.error}</Match> */}
      <Match when={exhibitionData()}>
        <div {...props}>
          <div class="main-content w-full text-16px">
            <p class="main-description">
              {exhibitionData()?.description ||
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"}{" "}
            </p>
          </div>
          <hr class="w-842px h-1px bg#nav-side-divider mb-40px opacity-40" />

          <section id="tasks-section">
            <h1 class="text-20px font-semibold">{exhibitionData()?.tasks_completed}</h1>
            <div class="flex flex-col gap-10px my-40px">
              <Atom.Foldable.Accordion
                dontCollapseOnOutsideClick
                arrowMode="hidden"
                class="border-2px border-solid border#p w-99%"
                activeClass="border!highlight"
                groupClass="flex flex-row space-x-2 py-3 px-3"
                title={({ isOpen }) => (
                  <section class="flex flex-row w-full text-16px">
                    <div class="font-700 font-semibold text-start w-full">
                      <span>More Information</span>
                      {" - "}
                      <span class="text#highlight">{`${
                        exhibitionData()?.more_info?.progress?.percentage?.split("-")?.[1] ?? "0% Completed"
                      }`}</span>
                    </div>
                    <i class={`!w-24px !h-24px icon-local-top-nav:down-arrow-dark ${isOpen ? "" : "rotate-90"}`} />
                  </section>
                )}
              >
                <InfoCard stage_id={stage_id} data={exhibitionData()?.more_info} />
              </Atom.Foldable.Accordion>
              {exhibitionData().moduls?.map(({ completion_percentage, module_name, sub_module_name, tasks }, i) => {
                return (
                  <Atom.Foldable.Accordion
                    dontCollapseOnOutsideClick
                    arrowMode="hidden"
                    class="border-2px border-solid border#p w-99%"
                    activeClass="border!highlight"
                    groupClass="flex flex-row space-x-2 py-3 px-3"
                    title={({ isOpen }) => (
                      <section class="flex flex-row w-full text-16px">
                        <div class="font-700 font-semibold text-start w-full">
                          <span>{`Module ${getCount(i + 1)} - ${module_name ?? "UNNAMED MODULE"}`}</span>
                          {" - "}
                          <span class="text#highlight">{`${completion_percentage ?? 0}% Completed`}</span>
                        </div>
                        <i class={`!w-24px !h-24px icon-local-top-nav:down-arrow-dark ${isOpen ? "" : "rotate-90"}`} />
                      </section>
                    )}
                  >
                    <div class="flex flex-col gap-10px justify-between text-16px pt-1 pb-2.5 px-3">
                      <p>
                        {tasks.filter((task) => task.status).length} of {tasks.length} Tasks completed.
                      </p>
                      {tasks.map((task) => {
                        return (
                          <div class="flex gap-10px items-center justify-between">
                            <div class="flex gap-10px">
                              <input class="w-25px h-25px" checked={task.status} type="checkbox" />
                              <p class="text-14px">{task.name}</p>
                            </div>
                            <p
                              onClick={() => goToTasks(module_name, sub_module_name, task.id)}
                              class="underline cursor-pointer"
                            >
                              {!task.status ? "view" : "edit"}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Atom.Foldable.Accordion>
                );
              })}
            </div>
          </section>
        </div>
      </Match>
    </Switch>
  );
}
